.product-container {
    width: 48%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
  }
  
  .product-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .offer-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }