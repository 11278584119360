.offer-container {
    width: 48%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .offer-detail {
    margin-bottom: 5px;
  }
  
  .label {
    font-weight: bold;
    color: #333;
    margin-right: 5px;
  }
  
  .offer-link {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .offer-link:hover {
    background-color: #0056b3;
  }